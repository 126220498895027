<template>
  <div class="grid h-screen" style="grid-template-rows: 55px 1fr 55px;">
    <header class="grid border-b bg-gray-100" style="grid-template-columns: 50px auto 55px;">
      <div>
        <router-link v-if="$route.name !== 'list'" to="/list" v-slot="{href, navigate}" custom>
          <button :href="href" @click="navigate" class="btn-icon active:translate-y-1"><Icon name="arrow-back.svg" /></button>
        </router-link>
      </div>
      <h2 class="text-center h-full uppercase font-semibold py-4">{{pageTitle}}</h2>
      <div>
        <router-link v-if="$route.name === 'list'" to="/list/share" v-slot="{href, navigate}" custom>
          <button :href="href" @click="navigate" class="btn-icon active:translate-y-1"><Icon name="send.svg" /></button>
        </router-link>
      </div>
    </header>
    <main class="overflow-auto pb-10">
      <router-view></router-view>
    </main>
    <AppFooter/>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import AppFooter from '@/components/AppFooter'
import Icon from '@/components/Icon'

export default {
  name: 'Stage',
  components: {
    AppFooter,
    Icon
  },
  computed: {
    ...mapState(['pageTitle']),
  },
  updated () {
    if (this.$route.name === 'list') {
      this.$store.commit('setPageTitle', 'Your List')
    }
  },
  mounted () {
    this.$store.commit('setPageTitle', 'Your List')
  }
}
</script>
